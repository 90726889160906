import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Tooltip from "rc-tooltip"
import "rc-tooltip/assets/bootstrap.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { Title, Slug, Description } = data.strapiPages
  const { description } = data.site.siteMetadata
  const links = data.allStrapiSocialLinks.nodes
  return (
    <Layout>
      <SEO title={Title} />
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-top"
        id={Slug}
      >
        <div className="w-100">
          <h2 className="mb-0 h1">
            Leo <span className="text-primary">Caseiro</span>
          </h2>
          <div className="subheading mb-5">{description}</div>
          <div className="lead mb-5">
            <ReactMarkdown source={Description} />
          </div>
          <div className="social-icons">
            {links.map(link => (
              <Tooltip key={link.id} placement="top" overlay={link.Title}>
                <a className="mb-2" title={link.Title} href={link.Link}>
                  <i className={link.Icon} />
                </a>
              </Tooltip>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query AboutMeQuery {
    strapiPages(Slug: { eq: "about-me" }) {
      Title
      Slug
      Description
    }
    site {
      siteMetadata {
        description
      }
    }
    allStrapiSocialLinks(sort: { fields: id, order: DESC }) {
      nodes {
        id
        Title
        Icon
        Link
      }
    }
  }
`
